import './App.css';
import { useState } from 'react';
import { useEffect } from 'react';
import { FiTrash, FiCircle } from 'react-icons/fi';
import { FaPlay, FaStop } from 'react-icons/fa';

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== "undefined") {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

function App() {
  const [timers, setTimers] = useLocalStorage("timers", []);

  const addNew = (e) => {
    setTimers(x => [...x, { id: Date.now(), time: 0, running: false, name: '' }]);
  }

  const remove = (idx) => {
    setTimers(x => x.filter((_, i) => i !== idx));
  }

  const start = (idx) => {
    setTimers(x => x.map((t, i) => idx === i ? {...t, running: true, startTime: new Date()} : t));
  }

  const stop = (idx) => {
    setTimers(x => x.map((t, i) => idx === i ? {...t, running: false, time: t.time + (new Date() - new Date(t.startTime))} : t));
  }

  const clear = (idx) => {
    setTimers(x => x.map((t, i) => idx === i ? {...t, running: false, time: 0} : t));
  }

  const setName = (idx, value) => {
    setTimers(x => x.map((t, i) => idx === i ? {...t, name: value} : t));
  }

  const zeroPad = (num) => (num + '').padStart(2, '0');

  const formatTime = (timer) => {
    const time = timer.running ? timer.time + (new Date() - new Date(timer.startTime)) : timer.time;
    var dt = new Date(time);
    return `${zeroPad(dt.getMinutes())}:${zeroPad(dt.getSeconds())}`
  }

  useEffect(() => {
    var t = setInterval(() => {
      setTimers(x => [...x]);
    }, 500);

    return () => clearInterval(t);
  }, [setTimers]);

  return (
    <div className="App">
      {timers.map((t, idx) => 
        <div key={t.id} className="timer">
          {t.running 
            ? <button className='stop' onClick={() => stop(idx)}><FaStop /></button>
            : <button className='start' onClick={() => start(idx)}><FaPlay /></button>}
           <span>{formatTime(t)}</span>
           <input type="text" onChange={e => setName(idx, e.target.value)} value={t.name} />
           {t.time || t.running ? <button onClick={() => clear(idx)}><FiCircle /></button> : <button onClick={() => remove(idx)}><FiTrash /></button>}
           
        </div>
      )}
      <div>
        <button onClick={addNew}>Add</button>
      </div>
    </div>
  );
}

export default App;
